﻿@import "libs";
@import "variables.less";
@import "navs.less";
@import "dropdowns.less";
@import "overlayloading.less";
@import "phasecontrol.less";

body {
    padding-top: 50px;
    padding-bottom: 20px;
}

/* Override the default bootstrap behavior where horizontal description lists 
   will truncate terms that are too long to fit in the left column 
*/
.dl-horizontal dt {
    white-space: normal;
}

.upload-button-wrapper {
    position: relative;
    overflow: hidden;
    margin: 0;
    display: inline-block;
    vertical-align: baseline;

    label {
        cursor: pointer;
    }

    input[type="file"] {
        position: absolute;
        visibility: hidden;
        display:none;
    }
}

.stepbadge {
    .badge;
    color: @brand-primary;
    background: none;
    border: none;
    font-size: 20px;
}

.list-group-item > .stepbadge {
    i {
        .glyphicon
    }
}

.list-group-item.disabled > .stepbadge {
    i {
        .glyphicon-ban-circle;
        color: @gray-light;
    }
}

.list-group-item.completed > .stepbadge {
    i {
        .glyphicon-check;
        color:green;
    }
}

.list-group-item.inprogress > .stepbadge {
    i {
        .glyphicon-edit;
        color: @brand-primary;
    }
}

.header-image {
    background-color: #000;

    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
    }
}

.ui-datepicker {
    z-index: 3 !important;
}

@media print {

    .adminbar,
    .nav,
    .navbar,
    .alert,
    .bs-callout,
    button {
        display: none;
    }

    input,
    textarea,
    .form-control {
        border: none;
        box-shadow: none;
        outline: none;
        padding-left: 0;
    }

    .container {
        width: 100%;
    }

    [class*="col-md"], [class*="col-sm"], [class*="col-xs"] {
        float: none;
    }
}
